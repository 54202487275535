// WARNING: DO NOT EDIT.  This file is automatically generated
// Written by aws-amplify-serverless-plugin/1.4.1 on 2023-11-29T19:20:39.876Z

const awsmobile = {
    aws_cloud_logic_custom: [
        {
            endpoint: 'https://tuuuctg1s3.execute-api.eu-central-1.amazonaws.com/stage',
            name: 'ApiGatewayRestApi',
            region: 'eu-central-1'
        }
    ],
    aws_cognito_identity_pool_id: 'eu-central-1:0cd52e12-bbac-4c03-839b-42bf009cb24d',
    aws_cognito_region: 'eu-central-1',
    aws_project_region: 'eu-central-1',
    aws_user_pools_id: 'eu-central-1_jF5EcibLI',
    aws_user_pools_web_client_id: '70tofe8jqrvq3712aefsk512rl'
};

export default awsmobile;
